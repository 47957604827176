var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mt-2",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',[_vm._v("العملات")])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-start"},[_c('ek-input-text',{staticClass:" flex-grow-1",attrs:{"min":1,"name":"curr","type":"number","fieldClass":"rounded-0 fieldClass","label":"سعر الصرف","rules":[
                      { type: 'required', message: 'سعر العملة مطلوب' },
                      { type: 'min_value:1', message: 'اقل قيمة هي ممكنة هي 1' }
                  ]},model:{value:(_vm.currencyList[1].value),callback:function ($$v) {_vm.$set(_vm.currencyList[1], "value", $$v)},expression:"currencyList[1].value"}}),_c('b-button',{staticClass:"edit-btn",attrs:{"type":"number","variant":"primary"},on:{"click":function($event){return _vm.updateCurrency({
                          id:_vm.currencyList[1].id,
                          name: _vm.currencyList[1].name,
                          value: _vm.currencyList[1].value,
                          currencyEnum: _vm.currencyList[1].currencyEnum,
                      })}}},[_c('unicon',{attrs:{"name":"pen","fill":"#fff","height":"16"}})],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }