<template>
  <div>
    <b-card class="mt-2">
      <template #header>
        <h4>العملات</h4>
      </template>
      <b-row>
          <b-col cols="12">
             <div class="d-flex align-items-start">
                <ek-input-text
                    :min="1"
                    name="curr"
                    v-model="currencyList[1].value"
                    type="number"
                    class=" flex-grow-1"
                    fieldClass="rounded-0 fieldClass"
                    label="سعر الصرف"
                    :rules="[
                        { type: 'required', message: 'سعر العملة مطلوب' },
                        { type: 'min_value:1', message: 'اقل قيمة هي ممكنة هي 1' }
                    ]"
                ></ek-input-text>
                <b-button
                    @click="
                        updateCurrency({
                            id:currencyList[1].id,
                            name: currencyList[1].name,
                            value: currencyList[1].value,
                            currencyEnum: currencyList[1].currencyEnum,
                        })
                    "
                    class="edit-btn"
                    type="number"
                    variant="primary"
                >
                    <unicon name="pen" fill="#fff" height="16"></unicon>
                </b-button>
             </div>
          </b-col>
        <!-- <b-col cols="6" v-for="currency in currencyList" :key="currency.id">
          <div class="d-flex align-items-start">
            <ek-input-text
                :min="1"
                :name="currency.name"
                v-model="currency.value"
                :style="{ width: '90%' }"
                type="number"
                fieldClass=" rounded-0 fieldClass"
                :label="currency.name"
                :rules="[{ type: 'required', message: 'سعر العملة مطلوب' }]"
            ></ek-input-text>
            <b-button
              @click="
                updateCurrency({
                    id:currency.id,
                  name: currency.name,
                  value: currency.value,
                  currencyEnum: currency.currencyEnum,
                })
              "
              class="edit-btn"
              type="number"
              variant="primary"
            >
              <unicon name="pen" fill="#fff" height="16"></unicon>
            </b-button>
          </div>
        </b-col> -->
      </b-row>
    </b-card>
  </div>
</template>

<script>
import EkInputText from "@/EK-core/components/EK-forms/EK-input-text";
import { mapState, mapActions } from "vuex";
// import EkTable from '@/EK-core/components/EK-table'
export default {
  components: {
    // EkTable
    EkInputText,
  },
  computed: {
    ...mapState({ currencyList: (state) => state.settings.currency }),
  },

  methods: {
    ...mapActions(["getCurrency","updateCurrency"]),
  },
  created() {
    this.getCurrency();
  },
};
</script>

<style lang="scss" >
.edit-btn {
  height: 38px;
  width: 50px;
  padding: 0;
  margin-top: 34px;
  border-radius: 0px 5px 5px 0px;
}
.fieldClass {
  border-radius: 5px 0px 0px 5px !important;
}
</style>