<template>
    <div>
        <ek-table
            no_select
            isPlus
            title="الإضافات"
            @plus="openDialog"
            @details="openDialog"
            :items="additionsList"
            :columns="cols"
        >
        </ek-table>
        <ek-dialog
            ref="addsDialog"
            @ok="setData"
            :isEdit="additionDto.id"
            @delete="deleteAddition(additionDto.id)"
        >
            <template #header>
                <h3>تعديل الإضافات</h3>
            </template>

            <template #body>
                <ValidationObserver ref="observer">
                    <ek-input-text
                        v-model="additionDto.name"
                        label="اسم المجموعة"
                        :rules="[{ type: 'required' }]"
                        name="اسم المجموعة"
                        placeholder="ادخل اسم المجموعة"
                    >
                    </ek-input-text>

                    <div class="input-g w-100">
                        <ek-input-text
                            class="ek-input"
                            name="اسم الخاصية"
                            label=" اضافة خاصية"
                            placeholder="ادخل اسم الخاصية"
                            v-model="attrName"
                        >
                        </ek-input-text>
                        <b-button
                            class="submit-btn rounded-circle ml-1 mt-2"
                            variant="primary"
                            @click="addAttr"
                        >
                            <unicon name="plus" height="24" fill="#fff">
                            </unicon>
                        </b-button>
                        <small
                            v-if="additionDto.attributes.length == 0"
                            class="text-danger"
                            >يجب اضافة خاصية واحدة على الاقل</small
                        >
                    </div>
                    <div
                        class="input-g w-100 align-items-start"
                        v-for="attr in additionDto.attributes"
                        :key="attr.id"
                    >
                        <ek-input-text
                            v-model="attr.name"
                            name="اسم الخاصية"
                            label="اسم الخاصية"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'يرجى ملئ الخاصية'
                                }
                            ]"
                        >
                        </ek-input-text>
                        <b-button
                            class="submit-btn rounded-circle ml-1"
                            :style="{ marginTop: '35px' }"
                            variant="danger"
                            @click="deleteAttr(attr)"
                        >
                            <unicon name="times" height="24" fill="#fff">
                            </unicon>
                        </b-button>
                    </div>
                </ValidationObserver>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import EkDialog from "@/EK-core/components/EK-dialog";
import EkTable from "@/EK-core/components/EK-table";
import { mapActions, mapGetters, mapState } from "vuex";
import EkInputText from "@/EK-core/components/EK-forms/EK-input-text";
import { handleDashLangChange } from "@/libs/event-bus";
export default {
    components: {
        EkInputText,
        EkDialog,
        EkTable,
        ValidationObserver
    },
    data: () => ({
        groupName: "",
        attrName: "",
        groups: [],
        cols: [
            {
                label: "اسم الاضافة",
                field: "name"
            },
            {
                label: "التفاصيل",
                field: "details"
            }
        ]
    }),

    computed: {
        ...mapState({
            additions: state => state.settings.additions,
            additionDto: state => state.settings.additionDto
        }),
        ...mapGetters(["additionsList"]),

        isEdit() {
            return !!this.additionDto.id;
        }
    },
    methods: {
        ...mapActions([
            "getAdditions",
            "updateAddition",
            "addAddition",
            "deleteMultiAdditions",
            "deleteAddition"
        ]),
        addAttr() {
            if (this.attrName) {
                this.additionDto.attributes.unshift({ name: this.attrName });
                this.attrName = "";
            }
        },
        deleteAttr(attr) {
            this.additionDto.attributes = this.additionDto.attributes.filter(
                el => el.name != attr.name
            );
        },
        openDialog(data) {
            if (data) {
                this.$store.commit("Set_Addition_Dto", {
                    name: data.row.name,
                    id: data.row.id,
                    attributes: data.row.attributes
                });
                this.$refs.addsDialog.open();
            } else {
                this.$store.commit("Set_Addition_Dto");
                this.$refs.addsDialog.open();
            }
        },

        setData() {
            this.$refs.observer.validate().then(suc => {
                if (suc && this.additionDto.attributes.length > 0) {
                    if (this.isEdit) this.updateAddition(this.additionDto);
                    else this.addAddition(this.additionDto);
                    this.attrName = "";
                    this.$refs.addsDialog.close();
                    this.$refs.observer.reset();
                }
            });
        }
    },
    created() {
        this.getAdditions();
        handleDashLangChange(this.getAdditions);
        this.$store.commit("Set_Search_Keys", ["name"]);
    }
};
</script>

<style lang="scss">
.input-g {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;

    .submit-btn {
        width: 38px !important;
        height: 38px !important;
        padding: 0 !important;
    }
}
</style>
