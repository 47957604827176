<template>
  <div class="g-settings">
    <b-row class="mt-5">
      <b-col cols="12" lg="6">
        <adds-on> </adds-on>
      </b-col>
      <b-col cols="12" lg="6" >
        <ValidationObserver ref="obs">
          <b-card class="w-100" header-class="pb-0" footer-class="p-1" body-class="p-1">
            <template #header>
                <h5>اوقات الدوام</h5>
            </template>
            <b-col cols="12">
              <b-row>
                <b-col>
                  <ek-input-select
                    placeholder=" اختر يوم بداية الدوام"
                    name="day"
                    label=" من يوم"
                    v-model="workHours.dayFrom"
                    :options="days"
                  ></ek-input-select>
                </b-col>
                <b-col>
                  <ek-input-select
                    v-model="workHours.dayTo"
                    placeholder="اختر يوم نهاية الدوام"
                    name="day"
                    label=" الى يوم"
                    :options="days"
                    :rules="[{ type: 'required', message: 'اختر اليوم' }]"
                  ></ek-input-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col cols="9">
                  <ek-input
                    v-model="workHours.hourFrom"
                    label="من الساعة"
                    :rules="[
                      { type: 'time', message: 'يرجى اختيار ساعة صحيحة' },
                    ]"
                    placeholder="مثال 08:00"
                    name="اوقات الدوام"
                  ></ek-input
                ></b-col>
                <b-col cols="3">
                  <ek-input-select
                    v-model="workHours.timeFrom"
                    name="day"
                    label=" التوقيت"
                    :options="timeList"
                  ></ek-input-select
                ></b-col>
              </b-row>
              <b-row>
                <b-col cols="9">
                  <ek-input
                    v-model="workHours.hourTo"
                    label="الى الساعة"
                    placeholder="مثال : 02:00"
                    :rules="[
                      { type: 'time', message: 'يرجى اختيار ساعة صحيحة' },
                    ]"
                    name="اوقات الدوام"
                  ></ek-input
                ></b-col>
                <b-col cols="3">
                  <ek-input-select
                    v-model="workHours.timeTo"
                    name="day"
                    label=" التوقيت"
                    :options="timeList"
                    title='أختر التوقيت'
                  ></ek-input-select
                ></b-col>
              </b-row>
            </b-col>

            <template #footer>
              <b-button variant="primary" @click="submitForm" class="w-25"
                >تعديل <unicon name="pen" fill="#fff" height="15"></unicon
              ></b-button>
            </template>
          </b-card>
        </ValidationObserver>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <currency></currency>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import EkInput from "@/EK-core/components/EK-forms/EK-input-text";
import EkInputSelect from "@/EK-core/components/EK-forms/EK-input-select";
import { mapActions, mapState } from "vuex";
import AddsOn from "./adds-on.vue";
import Currency from "./currency.vue";
export default {
  components: {
    EkInput,
    EkInputSelect,
    AddsOn,
    ValidationObserver,
    Currency,
  },
  data: () => ({
    days: [
      {
        name: "السبت",
        id: "السبت",
      },
      {
        name: "الاحد",
        id: "الاحد",
      },
      {
        name: "الأثنين",
        id: "الأثنين",
      },
      {
        name: "الثلاثاء",
        id: "الثلاثاء",
      },
      {
        name: "الأربعاء",
        id: "الأربعاء",
      },
      {
        name: "الخميس",
        id: "الخميس",
      },
      {
        name: "الجمعة",
        id: "الجمعة",
      },
    ],
    timeList: [
      {
        name: "am",
        id: "am",
      },
      {
        name: "pm",
        id: "pm",
      },
    ],
  }),
  computed: {
    ...mapState({
      workHours: (state) => state.settings.workHours,
      contactDto: (state) => state.settings.contactDto,
    }),
  },
  methods: {
    ...mapActions(["setWorkTime", "getSettings"]),
    submitForm() {
      this.$refs.obs.validate().then((suc) => {
        if (suc) {
            
            
            this.contactDto.workHours = `${this.workHours.timeTo}-${this.workHours.hourTo}-${this.workHours.timeFrom}-${this.workHours.hourFrom}-${this.workHours.dayFrom}-${this.workHours.dayTo}`;
            var formData = new FormData();
            formData.append("workHours",this.contactDto.workHours)
          this.setWorkTime(formData);
        }
      });
    },
  },
  created() {
    this.getSettings();
  },
};
</script>

<style>
</style>